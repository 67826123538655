<template>
  <div :class="$style.page">
    <div :class="$style.header">
      <Autocomplete
        :class="$style.search"
        :search="filters.search"
        placeholder="Поиск по названию"
        :valueNames="['name']"
        @querySearch="querySearchProducts"
        @selectItem="handleSelectProduct"
        @handleFindItems="getProducts"
        @input="filters.search = $event"
      />
      <el-button
        type="primary"
        @click="
          $router.push($options.ADDEVENT_ADMIN_ROUTES.CATALOG.PRODUCTS.CREATE)
        "
      >
        Создать продукт
      </el-button>
      <div :class="$style.filters">
        <el-button
          type="primary"
          :class="$style.openButton"
          @click="isFiltersOpen = !isFiltersOpen"
          >Фильтры</el-button
        >
        <Dropdown
          :isOpenDropDown="isFiltersOpen"
          title="Фильтры главного каталога"
          :class="$style.dropdown"
          @submitFilters="submitFilters()"
          @resetFilters="resetFilters()"
        >
          <div :class="$style.filter">
            <p>Доступен с:</p>
            <AdwCalendar
              id="orderDatePicker"
              :value="filters.reservationStart"
              :disabled-dates="disabledDates"
              minimum-view="day"
              maximum-view="day"
              placeholder="Дата начала аренды"
              is-hide-icon
              @selected="filters.reservationStart = $event"
            />
          </div>
          <div :class="$style.filter">
            <p>Доступен по:</p>
            <AdwCalendar
              id="orderDatePicker"
              :value="filters.reservationEnd"
              :disabled-dates="disabledDates"
              minimum-view="day"
              maximum-view="day"
              placeholder="Дата окончания аренды"
              is-hide-icon
              @selected="filters.reservationEnd = $event"
            />
          </div>
          <div :class="$style.filter">
            <p>Мин. цена:</p>
            <el-input-number
              v-model="filters.minPrice"
              :min="0"
            ></el-input-number>
          </div>
          <div :class="$style.filter">
            <p>Макс. цена:</p>
            <el-input-number
              v-model="filters.maxPrice"
              :min="0"
            ></el-input-number>
          </div>
          <div :class="$style.filter">
            <p>Объем:</p>
            <el-input-number
              v-model="filters.volumes"
              :min="0"
            ></el-input-number>
          </div>
          <div :class="$style.filter">
            <p>Продукты:</p>
            <el-select
              v-model="filters.productIds"
              placeholder="Выберите"
              style="width: 100%"
              v-el-select-loadmore="loadMoreFilterProducts"
              filterable
              remote
              multiple
              :remote-method="searchFilterProducts"
              :loading="filterProductsLoading"
            >
              <el-option
                v-for="item in filterProducts"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </div>
        </Dropdown>
      </div>
    </div>
    <el-table :data="products" stripe @sort-change="onSortChange">
      <el-table-column prop="priority" label="Приоритет" sortable />
      <el-table-column label="Изображение" width="120"
        ><template slot-scope="scope">
          <div :class="$style.image">
            <Gallery
              :images="setGalleryImages(scope.row?.images)"
              :image="setupImagesListData(scope.row?.images)?.original"
            />
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="name" label="Название" sortable />
      <el-table-column prop="basicPricePerDay" label="Цена за день" sortable />
      <el-table-column
        prop="additionalPricePerDay"
        label="Доп. Цена за день"
        sortable
      />
      <el-table-column prop="stocks" label="Кол-во на складе" sortable />
      <el-table-column label="Активный">
        <template slot-scope="scope">
          <el-checkbox :class="$style.readOnly" :value="scope?.row?.isActive" />
        </template>
      </el-table-column>
      <el-table-column prop="vendorCode" label="Артикул" />
      <el-table-column width="120">
        <template slot-scope="scope">
          <ActionButtons
            name="trash"
            :view-link="scope.row?.msUrl"
            :edit-link="
              getRoute({
                route: $options.ADDEVENT_ADMIN_ROUTES.CATALOG.PRODUCTS.UPDATE,
                params: { id: scope?.row?.id },
              })
            "
            @delete="deleteProduct(scope?.row)"
          />
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      v-if="totalPages > 1"
      background
      layout="prev, pager, next"
      :page-size="query.limit"
      :total="count"
      :current-page.sync="query.page"
      :class="$style.pagination"
      @current-change="getProducts"
    >
    </el-pagination>
  </div>
</template>
<script>
import delivery from '@/delivery'
import notifications from '@/mixins/notifications'
import images from '@/mixins/images.js'
import { debounce } from '@/helpers/debounce.js'

import { ADDEVENT_ADMIN_ROUTES, getRoute } from '@/constants/routing'
import { SORTING_TYPES } from '@/constants/common'
import { camelToSnakeCase } from '@/helpers/convertString'
import { EL_TABLE_SORTING_TYPES } from '@/constants/common'
import { DAY_IN_MS } from '@/constants/common'

import ActionButtons from '@/components/atoms/ActionsButtons.vue'
import Gallery from '@/components/atoms/Gallery.vue'
import Autocomplete from '@/components/atoms/Autocomplete'
import Dropdown from '@/components/atoms/Dropdown'

export default {
  components: { ActionButtons, Gallery, Autocomplete, Dropdown },
  ADDEVENT_ADMIN_ROUTES,
  EL_TABLE_SORTING_TYPES,
  mixins: [notifications, images],
  data() {
    return {
      isFiltersOpen: false,
      categories: [],
      filterProducts: [],
      filterProductsLoading: false,
      filterProductsOptions: {
        limit: 20,
        page: 1,
        total: 0,
        search: '',
        orderBy: 'priority',
        orderDir: 'desc',
      },
      products: [],
      filters: {
        search: null,
        minPrice: null,
        maxPrice: null,
        volumes: null,
        productIds: [],
        reservationStart: null,
        reservationEnd: null,
      },
      query: {
        page: this.$route.query.page ?? 1,
        limit: 20,
        orderBy: this.$route.query.orderBy ?? 'priority',
        orderDir: this.$route.query.orderDir ?? SORTING_TYPES.DESC,
      },
      
      count: 0,
    }
  },
  async created() {
    await Promise.all([
      this.getCategories(),
      this.getProducts(),
      this.getFilterProducts(),
    ])
    this.filterProductsSearch = debounce(this.getFilterProducts, 500)
    this.$router.push({query: this.query})
  },
  async updated() {
    if (JSON.stringify(this.$router.query) !== JSON.stringify(this.query))
      this.$router.push({query: this.query})
  },
  computed: {
    totalPages() {
      return Math.ceil(this.count / this.query.limit)
    },
    disabledDates() {
      return { to: new Date(Date.now() - DAY_IN_MS) }
    },
  },
  methods: {
    async onSortChange({ prop, order }) {
      if (prop && order) {
        this.query.orderBy = camelToSnakeCase(prop)
        this.query.orderDir = EL_TABLE_SORTING_TYPES[order] ?? null
      }
      this.query.page = 1
      await this.getProducts()
    },
    async getProducts() {
      const loading = this.$loading({
        lock: true,
      })

      const { value, error } =
        await delivery.AddeventServiceCore.ProductsActions.getList({
          ...this.query,
          ...this.filters,
        })

      loading.close()

      if (error) {
        return
      }

      this.products = value?.data ?? []
      this.count = value?.meta?.count ?? 0
    },
    async getFilterProducts() {
      const loading = this.$loading({
        lock: true,
      })

      const { value, error } =
        await delivery.AddeventServiceCore.ProductsActions.getList(
          this.filterProductsOptions,
        )

      loading.close()

      if (error) {
        return
      }

      this.filterProducts = [...this.filterProducts, ...value.data]

      this.filterProductsOptions.total = value.meta.count
    },
    async getCategories() {
      const loading = this.$loading({
        lock: true,
      })

      const { value, error } =
        await delivery.AddeventServiceCore.CategoriesActions.getList()

      loading.close()

      if (error) {
        return
      }

      this.categories = value?.data ?? []
    },
    async deleteProduct(product) {
      const isConfirm = confirm(
        `Вы точно хотите удалить продукт: ${product.name}`,
      )

      if (!isConfirm) return

      const loading = this.$loading({
        lock: true,
      })

      const { error } =
        await delivery.AddeventServiceCore.ProductsActions.delete(product.id)

      loading.close()

      if (error) {
        this.showNotification('Ошибка удаления продукта', 'error')
        return
      }

      this.showNotification('Продукт успешно удален', 'success')
      await this.getProducts()
    },
    async querySearchProducts({ queryString, setSearchItems }) {
      const query = {
        limit: 10,
        search: queryString,
      }
      const loading = this.$loading({
        lock: true,
      })
      const { value, error } =
        await delivery.AddeventServiceCore.ProductsActions.getList(query)
      loading.close()
      if (error) return
      setSearchItems(value.data)
    },
    loadMoreFilterProducts() {
      const { page, limit, total } = this.filterProductsOptions
      if (limit * page < total) {
        this.filterProductsOptions.page++
        this.getFilterProducts()
      }
    },
    async searchFilterProducts(search) {
      this.filterProductsOptions.page = 1
      this.filterProductsOptions.search = search
      this.filterProductsLoading = true
      this.filterProducts = []
      await this.filterProductsSearch()
      this.filterProductsLoading = false
    },
    handleSelectProduct(selectedProduct) {
      this.$router.push(
        this.getRoute({
          route: ADDEVENT_ADMIN_ROUTES.CATALOG.PRODUCTS.UPDATE,
          params: { id: selectedProduct?.id },
        }),
      )
    },
    async submitFilters() {
      if (this.filters.reservationStart && !this.filters.reservationEnd)
        return this.showNotification(
          'Выберите дату окончания аренды',
          'warning',
        )

      if (!this.filters.reservationStart && this.filters.reservationEnd)
        return this.showNotification('Выберите дату начала аренды', 'warning')

      const startRentDate = new Date(this.filters.reservationStart).getTime()
      const finishRentDate = new Date(this.filters.reservationEnd).getTime()

      if (startRentDate > finishRentDate)
        return this.showNotification(
          `Дата начала не может быть позже, чем дата окончания `,
          'error',
        )

      this.isFiltersOpen = false

      await this.getProducts()
    },
    resetStateFilters() {
      this.filters = {
        search: null,
        minPrice: null,
        maxPrice: null,
        volumes: null,
        productIds: [],
        reservationStart: null,
        reservationEnd: null,
      }
    },
    async resetFilters() {
      this.isFiltersOpen = false

      this.resetStateFilters()
      await this.getProducts()
    },
    getRoute({ route, params }) {
      return getRoute({ route, params })
    },
    setGalleryImages(images) {
      if (!images) {
        return []
      }

      return images.map((img) => this.$configData.s3_link + img.original)
    },
  },
}
</script>
<style lang="scss" module>
.page {
  padding: 1rem;
  .readOnly {
    pointer-events: none;
  }

  .header {
    position: sticky;
    top: 3rem;
    display: flex;
    gap: 1rem;
    padding: 1rem 0;
    z-index: $z-index-sticky;
    background-color: $smoky;
  }

  .filters {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .dropdown {
      left: 7rem;
      top: 0;
      & > div {
        display: flex;
        flex-direction: column;
        gap: 0.5rem;

        .filter {
          display: grid;
          align-items: center;
          grid-template-columns: 1fr 2fr;
          gap: 0.5rem;
        }
      }
    }
  }

  .image {
    width: 6.25rem;
    height: 6.25rem;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .pagination {
    @include pagination;
  }
}
</style>
