<template>
  <div>
    <LightGallery
      :images="images"
      :index="index"
      :disable-scroll="true"
      :class="$style.gallery"
      @close="index = null"
    />
    <div :class="$style.block" @click="index = images.length > 0 ? 0 : null">
      <img :src="image" />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    images: {
      type: Array,
      default: () => ['/img/gallery/default.jpg']
    },
    image: {
      type: String,
      default: () => '/img/gallery/default.jpg'
    }
  },
  data() {
    return {
      index: null
    }
  }
}
</script>

<style lang="scss" module>
.block {
  cursor: zoom-in;

  img {
    width: 100%;
  }
}
.gallery > div > div:nth-child(2) img {
  max-height: 35rem;
  padding: 0 0.5rem;
}
</style>
